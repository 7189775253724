<template>
  <div class="page" style="background-color:#F7F9FE;">
    <div style="width: 80%;margin: 20px auto;">
      <div
        style="height: 44px;background-color: #E8EDF8;text-align: center;line-height: 44px;margin-bottom: 20px;font-size: 16px;"
        class="h2"
      >活动报名（{{ name }}）</div>
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="120px" label-suffix=":">
        <el-form-item label="公司名称" required>
          <el-input v-model="form.companyName" placeholder="请输入公司名称"></el-input>
        </el-form-item>
        <el-form-item label="公司简介" required>
          <el-input
            v-model="form.companyIntroduction"
            type="textarea"
            placeholder="请输入公司的简介"
            rows="4"
            resize="none"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人" required>
          <el-input v-model="form.contactName" placeholder="请输入联系的姓名"></el-input>
        </el-form-item>
        <el-form-item label="联系方式" required>
          <el-input v-model="form.contactPhone" placeholder="请输入联系人的电话号码"></el-input>
        </el-form-item>
        <div style="display: flex;justify-content: center;">
          <el-button @click="goback" style="margin-right: 50px;width: 150px;">取消</el-button>
          <el-button type="primary" @click="onSubmit" style="width: 150px;">提交</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      article_id: null,
      name: null,
      detailType: 'environment',
      form: {},
      rules: {
        companyName: [{
          required: true,
          message: '请输入公司名称',
          trigger: 'blur'
        }
        ],
        contactName: [{
          required: true,
          message: '请输入联系的姓名',
          trigger: 'blur'
        }],
        contactPhone: [{
          required: true,
          message: '请输入联系人的电话号码',
          trigger: 'blur'
        }],
      },
    }
  },
  computed: {

  },
  created(options) {
    console.log('options = ', options)
    console.log('this.$route.query = ', this.$route.query)
    const article_id = this.$route.query?.menu_id
    if (article_id) {
      this.article_id = article_id
    }
    const name = this.$route.query?.name
    if (name) {
      this.name = name
    }
    const detailType = this.$route.query?.detailType
    if (detailType) {
      this.detailType = detailType
    }

  },
  mounted() {

  },
  methods: {
    apply() { },
    onSubmit() {
      //TODO 
      if (!this.form.companyName) {
        this.$message.error('请输入公司名称');
        return
      }
      if (!this.form.contactName) {
        this.$message.error('请输入联系人');
        return
      }
      if (!this.form.contactPhone) {
        this.$message.error('请输入联系方式');
        return
      }
      const params = {
        ...this.form,
        // 'id': this.article_id
      }
      this.formLoading = true
      // if (this.detailType == 'ds-environment') {
      //   this.$api.digitalServices.environmentApply(params).then((res) => {
      //     this.formLoading = false
      //     const code = res.data.code
      //     if (code == 200) {
      //       this.$message({
      //         message: '提交成功',
      //         type: 'success'
      //       });
      //     }
      //   }).catch(() => {
      //     this.formLoading = false
      //   })
      // }
      // else if (this.detailType == 'ds-calculation') {
      //   this.$api.digitalServices.calculationApply(params).then((res) => {
      //     this.formLoading = false
      //     const code = res.data.code
      //     if (code == 200) {
      //       this.$message({
      //         message: '提交成功',
      //         type: 'success'
      //       });
      //     }
      //   }).catch(() => {
      //     this.formLoading = false
      //   })
      // }
      // else if (this.detailType == 'ds-cs') {
      //   this.$api.digitalServices.csApply(params).then((res) => {
      //     this.formLoading = false
      //     const code = res.data.code
      //     if (code == 200) {
      //       this.$message({
      //         message: '提交成功',
      //         type: 'success'
      //       });
      //     }
      //   }).catch(() => {
      //     this.formLoading = false
      //   })
      // }
      console.log('this.form = ', this.form)
      this.$api.solicitationCloud.activityApply(params).then((res) => {
        this.formLoading = false
        const code = res.data.code
        if (code == 200) {
          this.$message({
            message: '提交成功',
            type: 'success'
          });
        }
      }).catch(() => {
        this.formLoading = false
      })
    },
    goback() {
      this.$router.go(-1)
    },
  }
}
</script>
<style scoped lang="less">
.page {
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  background: #fff;
  padding: 20px;
}
.notice-title {
  text-align: center;
  margin-top: 35px;
  font-weight: 700;
}

.time {
  text-align: center;
  margin: 10px 0;
  // padding-bottom: 30px;
  // border-bottom: 2px dashed #cccccc;
}

.divider-bottom {
  width: 100%;
  height: 1px;
  border-bottom: 2px dashed #cccccc;
  margin: 20px 0;
}

.notice-content {
  margin-top: 30px;
  line-height: 40px;
}
.sub-title {
  color: #908d8d;
}
.detail-button {
  text-align: center;
  height: 40px;
  width: 150px;
  border: solid 1px #dfdfdf;
  line-height: 40px;
  cursor: pointer;
  border-radius: 15px;
  background-color: #0046c0;
  color: #fff;
}
</style>
